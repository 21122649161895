import React from "react";
import twitter from '../../../static/images/twitter-pr.png';
import instagram from '../../../static/images/instagram-pr.png';
import youtube from '../../../static/images/youtube-pr.png';
import linkedin from '../../../static/images/linkedin-pr.png';
import image_url_map from '../../../static/images/constants/image_constants';
const amber = image_url_map['meet_amber_bottom'];

const NewsfeedPR = () => (
    <div className="pd-top-150 padding-20-mobile">
        <div className="columns column is-11 mar-auto">
            <div className="column is-4">
                <p className="has-text-weight-bold is-size-4 mg-bottom-5 mg-top-12">Contact PR</p>
                <p className="has-text-weight-bold is-size-5 mg-bottom-40"><a href="mailto:press@infeedo.com" className="anchor-hov-blue" target="_blank" rel="noopener noreferrer">press@infeedo.com</a></p>
                <p className="has-text-weight-bold is-size-4">Follow inFeedo</p>
                <div className="mg-bottom-10">
                    <a href="https://www.linkedin.com/company/infeedo" target="_blank" rel="noopener noreferrer"><img src={linkedin} width="50px" className="pd-10 no-pd-left" alt="linkedin" /></a>
                    <a href="https://www.youtube.com/channel/UCGOPlzzyE3QOog6WlkUdAYg/videos" target="_blank" rel="noopener noreferrer"><img src={youtube} width="60px" className="pd-10" alt="youtube" /></a>
                    <a href="https://www.instagram.com/infeedo" target="_blank" rel="noopener noreferrer"><img src={instagram} width="60px" className="pd-10" alt="instagram" /></a>
                    <a href="https://twitter.com/inFeedo" target="_blank" rel="noopener noreferrer"><img src={twitter} width="60px" className="pd-10" alt="twitter" /></a>
                </div>
                <div className="is-hidden-tablet mg-bottom-30 mg-top-12">
                    <p className="has-text-weight-bold is-size-4 mg-bottom-10">Learn More about inFeedo</p>
                    <a href="https://www.youtube.com/channel/UCGOPlzzyE3QOog6WlkUdAYg/" target="_blank" rel="noopener noreferrer" className="button is-blue">
                        Learn More
                    </a>
                </div>
            </div>
            <div className="column is-5 has-text-left is-hidden-mobile pd-left-70">
                <p className="has-text-weight-bold is-size-3">Learn More about inFeedo</p>
                <br />
                <a href="https://www.youtube.com/channel/UCGOPlzzyE3QOog6WlkUdAYg/" target="_blank" rel="noopener noreferrer" className="button is-blue">
                    Learn More
                </a>
            </div>
            <div className="column relative-position height-width-300 is-hidden-mobile">
                <img src={amber} className="amber-bottom-img" alt="amber" />
            </div>
        </div>
    </div>
)

export default NewsfeedPR

