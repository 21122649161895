import React, { Component } from 'react';
import PropTypes from "prop-types";
import ResourceModal from './ResourceModal';
class ResourceCard extends Component {

    state = {
        is_active: false,
    }

    openModal = () => {
        this.setState({ is_active: true });
    }

    onClose = () => {
        this.setState({ is_active: false });
    }

    render() {
        const { is_active } = this.state;
        const { card_data } = this.props;
        return (
            <div className="card card-shadow card-hov">
                {
                    card_data.image ?
                        <div className="card-image">
                            <figure className="image">
                                <img src={card_data.image} className="radius-top-10" alt="Placeholder" />
                            </figure>
                        </div> : null
                }
                <div className="card-content pd-top-15  has-background-white-bis">
                    <div className="media mg-bottom-20">
                        {card_data.title ?
                            <div className="media-content">
                                <p className="title is-6 has-text-grey has-text-weight-bold pd-top-5 no-overflow">{card_data.title}</p>
                            </div> : null
                        }
                    </div>
                    <div className="content font-15 has-text-left has-text-grey-dark">
                        {card_data.text}
                    </div>
                    <div>
                        <button id={`${card_data.id}_download`} className="button  is-outlined is-link" onClick={this.openModal}>Download</button>
                        {is_active ? <ResourceModal resource_id={card_data.id} resource_link={card_data.link} resource_img={card_data.image} resource_title={card_data.text} onClose={this.onClose} is_active={is_active} /> : null}
                    </div>
                </div>
            </div>)
    }

}

ResourceCard.propTypes = {
    card_data: PropTypes.object,
}

export default ResourceCard