import React, { Component } from 'react';
import Resources from './Resources';
import classnames from 'classnames';
import resource_img from '../../../static/images/resourcesIcon.png';
import { Link } from "gatsby";
export default class Resource extends Component {

    state = {
        selected_tab: 'caseStudies'
    }
    // component mounts
    //----------------------------------------------
    componentDidMount() {
        this.setSelectedtab()
    }

    //component updates
    //----------------------------------------------
    componentDidUpdate() {
        this.setSelectedtab();
    }

    //set the selected tab type
    //----------------------------------------------
    setSelectedtab = () => {
        const { selected_tab } = this.state
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tab_type = urlParams.get('type');
        if (tab_type !== null) {
            if (tab_type.length && tab_type !== selected_tab && (tab_type === 'reports' || tab_type === 'caseStudies')) {
                this.setState({ selected_tab: tab_type });
            }
        }

    }
    render() {
        const { selected_tab } = this.state;
        return (
            <div className={selected_tab ? ' has-text-centered social-mentions' : 'has-text-centered article-mentions'}>
                <h1 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold pd-top-75">
                    <span>Thought Leadership for HR Superheroes</span>
                    <img src={resource_img} width="50px" alt="logo" className="middle mg-10" />
                </h1>
                <h2 className="is-size-5-tablet is-size-6-mobile pd-10"><b>Your one stop-shop for case studies, guides and reports</b><br /> Explore and find everything you need to learn about Amber and inFeedo from us and our customers.</h2>
                <div className="columns mg-top-20 flex-wrap bg-path-4 no-mg-right-left">
                    <div className="tabs is-centered column is-10 mar-auto">
                        <ul>
                            <Link to="/resources?type=caseStudies" >
                                <li className={classnames('', { 'is-active': selected_tab === 'caseStudies' })}><button> Case Studies</button></li>
                            </Link>
                            <Link to="/resources?type=reports" >
                                <li className={classnames('', { 'is-active': selected_tab === 'reports' })}><button>Reports & Guides</button></li>
                            </Link>
                            {/* <li onClick={this.changetab.bind(this, 'ebooks')} className={classnames('', {'is-active': selected_tab === 'ebooks'})}><button>Ebooks</button></li>
                        <li onClick={this.changetab.bind(this, 'whitepaper')} className={classnames('', {'is-active': selected_tab === 'whitepaper'})}><button>Whitepaper</button></li> */}
                        </ul>
                    </div>
                    <div className="column is-11 mar-auto">
                        <Resources tab={selected_tab} />
                    </div>
                </div>

            </div>
        )
    }
}
